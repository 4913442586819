<template>
  <div class="">
    <div class="row">
      <div class="col-sm-8">
        <div>
          <h2 class="pb-2">{{ $t("ApplicationCounseling.title") }}</h2>
          <div v-html="$t('ApplicationCounseling.desc')"></div>
        </div>
      </div>
      <div class="col-sm-4 img">
        <el-image
          src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/admissions.jpg"
        />
      </div>
    </div>
    <el-divider></el-divider>
    <div class="pt-2">
      <h3 class="pb-4">{{ $t("ApplicationCounseling.serviceTitle") }}</h3>
      <template>
        <el-table
          :data="tableData"
          :span-method="objectSpanMethod"
          :row-style="tableRowStyle"
        >
          <el-table-column
            :label="
              $t('ApplicationCounseling.serviceTableColumnTitle.singleService')
            "
            align="center"
          >
            <el-table-column prop="item" align="center" width="60">
              <template slot-scope="scope">
                <div :class="scope.$index < 39 ? 'verticalText' : ''">
                  <strong>{{ scope.row.label }}</strong>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="item" align="center"  width="60">
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column
              prop="item"
              :label="$t('ApplicationCounseling.serviceTableColumnTitle.item')"
              header-align="center"
              align="left"
              width="300"
            ></el-table-column>
            <el-table-column
              :label="
                $t('ApplicationCounseling.serviceTableColumnTitle.describe')
              "
              header-align="center"
            >
              <template slot-scope="scope">
                <span v-html="scope.row.detail" />
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    SERVICE_COUNT() {
      return 40;
    },
    tableData() {
      return Array.from(new Array(this.SERVICE_COUNT)).map((v, i) => ({
        item: this.$t(`ApplicationCounseling.service.${i + 1}.title`),
        detail: this.$t(`ApplicationCounseling.service.${i + 1}.describe`),
        label: this.$t(`ApplicationCounseling.service.${i + 1}.label`)
      }));
    }
  },
  methods: {
    toRoute(name) {
      this.$router.push({
        name: name
      });
    },
    tableRowStyle({ rowIndex }) {
      let background = "#ffffff";
      if (rowIndex === 0) {
        background = "#f8d5e8";
      } else if (rowIndex >= 1 && rowIndex <= 9) {
        background = "#fef8e7";
      } else if (rowIndex >= 10 && rowIndex <= 28) {
        background = "#f4feea";
      } else if (rowIndex >= 29 && rowIndex <= 38) {
        background = "#e9f4fe";
      } else if (rowIndex === 39) {
        background = "#e3e0fc";
      }

      return { background };
    },
    objectSpanMethod({ rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        switch (rowIndex) {
          case 0:
            return { rowspan: 1, colspan: 1 };
          case 1:
            return { rowspan: 9, colspan: 1 };
          case 10:
            return { rowspan: 19, colspan: 1 };
          case 29:
            return { rowspan: 10, colspan: 1 };
          case 39:
            return { rowspan: 1, colspan: 1 };
          default:
            return { rowspan: 0, colspan: 0 };
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.img {
  padding-left: 20px;
  padding-top: 50px;
}

::v-deep .el-table__body tr:hover > td {
  background-color: rgba(0, 0, 0, 0) !important;
}

.verticalText {
  letter-spacing: 10px;
  margin: 0 auto;
  margin-top: 10px;
  -webkit-writing-mode: vertical-lr;
  writing-mode: vertical-lr;
}

@media (max-width: 992px) {
  .img {
    padding-top: 20px;
    padding-left: 20px;
  }
}
</style>
